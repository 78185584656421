<template>
    <div class="mt-1">
		<!-- <b-alert show variant="danger" v-if="!benefits.length">
            <div class="alert-body text-center"> 😊 <span>Es necesario ver los beneficios para hacer una reserva</span></div>
        </b-alert>       -->
		<div class="border p-1">
			<div class="d-flex justify-content-between">
				<h4 class="card-title">Realizar Reserva </h4>
				<div>
					<b-badge :variant="searchBooking.nights > 0 ? 'success':'danger'" class="b-nights "> <b>  Noches: </b> <span>{{searchBooking.nights}}</span> </b-badge>
				</div>
			</div>

			<b-overlay :show="searchingByEdit" rounded class="half-margin">
				<validation-observer tag="form" v-slot="{ invalid }" ref="searchAvailibilityForm">
					<b-form @submit.prevent="handlerSearch">
						<b-row>
							<b-col md="">
								<validation-provider name="Hotel" rules="required">
									<b-form-group slot-scope="{ valid, errors }" label="Hotel*">
										<b-form-select
											:state="errors[0] ? false : valid ? true : null"
											v-model="searchBooking.hotel"
										>
											<option :value="null">Seleccione hotel</option>
											<option v-for="hotel in hotelsList" :key="hotel.id" :value="hotel.id" > {{ hotel.name }} </option>
										</b-form-select>
										<b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
									</b-form-group>
								</validation-provider>
							</b-col>
							<b-col md="2">
								<validation-provider name="Vivienda" rules="required">
									<b-form-group slot-scope="{ valid, errors }" label="N° de vivienda*">
										<b-form-input
											v-model="searchBooking.numberHouse"
											type="number"
											@keypress="onlyNumber"
											:state="errors[0] ? false : valid ? true : null"
											placeholder="Buscar Vivienda"
										/>
	
										<b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
									</b-form-group>
								</validation-provider>
							</b-col>
							<b-col md="3">
							<!-- {{ searchBooking.rangeDate }} {{ configRangeDate }} -->
								<b-form-group label="Check in - Check out*">
									<flat-pickr
										v-model="searchBooking.rangeDate"
										class="form-control"
										@input="setCheckInChekOut"
										:config="configRangeDate"
									/>
								</b-form-group>
							</b-col>
							<b-col md="2">
								<validation-provider name="adultos" rules="required">
									<b-form-group slot-scope="{ valid, errors }" label="Adultos*">
										<b-form-select
											v-model="searchBooking.adultsNumber"
											:state="errors[0] ? false : valid ? true : null"
										>
											<option v-for="(adulto, index) in adultOptions" :key="index" :value="adulto.value" > {{ adulto.title }} </option>
										</b-form-select>
										<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
									</b-form-group>
								</validation-provider>
							</b-col>
							<b-col md="2">
								<validation-provider name="niños" rules="required">
									<b-form-group slot-scope="{ valid, errors }" label="Niños*">
										<b-form-select
											v-model="searchBooking.numberChildren"
											:options="childrenOptions"
											@change="setChilds"
											:state="errors[0] ? false : valid ? true : null"
										></b-form-select>
										<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
									</b-form-group>
								</validation-provider>
							  </b-col>
						</b-row>
						<hr>
						<b-row v-if="searchBooking.numberChildren > 0" >
							<b-col md="2" v-for="(child, index) in searchBooking.ninos" :key="index">
								<label :for="'AgeChild'+index">Edad niño {{index+1}}*</label>
								<b-input-group >
									<b-form-select
										:id="'AgeChild'+index"
										v-model="searchBooking.ninos[index].edad"
									>
										<option v-for="(edad, index) in ageChildrenOptions" :key="index" :value="edad.value">{{edad.text}} </option>
									</b-form-select>
									<b-input-group-append>
										<b-button variant="danger" size="sm" @click="deleteChild(child.key)"  >
											<b-icon icon="trash-fill" ></b-icon>
										</b-button>
									</b-input-group-append>
								</b-input-group>
							</b-col>
						</b-row>
						<hr v-if="searchBooking.numberChildren > 0" >
	
						<div class="d-flex justify-content-between mt-2">
							<b-button @click="resetSearchForm" variant="warning" class="mr-1" > Limpiar </b-button>
							<b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" :disabled="invalid" >Buscar</b-button>
						</div>
					</b-form>
				</validation-observer>
			</b-overlay>

			<div class="alertas">
				<div class="alert-msg alert-msg__danger mt-1 " v-if="validityRangeDates">
					<p class=" alert-p text-danger"> <feather-icon icon="CircleIcon" class="mr-50" /> {{ validityRangeDates.message }}</p>
				</div>	
				<div class="alert-msg alert-msg__danger " v-if="validityContract">					
					<div class=" alert-p text-danger" v-html="validityContract.message"></div>
				</div>
				<div class="alert-msg alert-msg__danger " v-if="validityCategoryRoom">
					<p class="alert-p text-danger"> <feather-icon icon="CircleIcon" class="mr-50" /> {{ validityCategoryRoom.message }}</p>					
				</div>
				<div class="alert-msg alert-msg__danger " v-if="noNightsAvailable">					
					<p class=" alert-p text-danger"> <feather-icon icon="CircleIcon" class="mr-50" /> No tiene noches disponibles</p>
				</div>				
			</div>			

			<!-- <div class="alertas" v-if="bookingAvailibility">
				<div class="alert-msg alert-msg__danger mt-1" v-if="bookingAvailibility.authorization">					
					<p class="alert-p text-danger"> <feather-icon icon="CircleIcon" class="mr-50" /> La reserva tendra que pasar por una autorización previa para poder confirmarla</p>					
				</div>
				<div class="alert-msg alert-msg__warning" v-if="bookingAvailibility.message !='' ">					
					<p class="alert-p text-warning"> <feather-icon icon="CircleIcon" class="mr-50" /> {{ bookingAvailibility.message }}</p>					
				</div>
				<div class="alert-msg alert-msg__warning" v-if="bookingAvailibility.additional==true">					
					<p class="alert-p text-warning"> <feather-icon icon="CircleIcon" class="mr-50" /> La reserva se tomará como adicional</p>										
				</div>
				<div class="alert-msg alert-msg__warning" v-if="bookingAvailibility.blackoutValid">					
					<p class="alert-p text-warning"> <feather-icon icon="CircleIcon" class="mr-50" /> La reserva tiene un blackout</p>									
				</div>
			</div> -->

		
		</div>

		<div class="mt-1">
			<div class="loadRooms" v-if="isValidatingPreliminarData">
				<b-spinner label="Spinning"/>
				<p>Validando datos de búsqueda</p>
			</div>
		</div>
    </div>
</template>

<script>
import { mapActions,  mapState,mapMutations  } from 'vuex'
import * as moment from 'moment'
import { toJson, showAlertMessage, stringAleatorio, sortalphabetically } from '@/helpers/helpers'
import { adultOptions, childrenOptions, ageChildrenOptions } from '@/data/data'
import { reservetype, occupanttype } from '@/data/fivesClub'
import { utils } from '@/modules/fivesClub/mixins/utils'
import { heightFade } from '@core/directives/animations'


import Ripple from 'vue-ripple-directive'
import flatPickr from "vue-flatpickr-component";

export default {
    directives: { Ripple, heightFade },
    mixins: [utils],
	props:{
		benefits:{
			type: Array,
			required: true,
		},
		year: {
			type: Number,
			required: true,
		},
		oneBenefitIsSelected:{
			type: Boolean,
			required: true,
		},
		owner:{
			type: Object,
            default: null
		},
		contract:{
            type: Object,
            default: null
        },
		rooms: {
			type: Array,
			required: true,

		},
		filterParams:{
			type: Object,
			required: true
		}
	},
	components: { flatPickr },
	async created() {
		this.searchBooking = structuredClone(this.filterParams)
		if (this.searchBooking?.toEdit) {
			this.searchingByEdit = true
			await this.handlerSearch()
			this.searchBooking.toEdit = false
			this.searchingByEdit = false
		}
	},
	data(){
		return {
            numberHouse: null,
			adultOptions,
			childrenOptions, ageChildrenOptions,
			occupanttype, reservetype,
            currentYear: new Date().getFullYear(),
			validityContract: null,
			validityRangeDates: null,
			validityCategoryRoom: null,
			noNightsAvailable: false,
			searchingByEdit:false,
			isValidatingPreliminarData: false
		}
	},
	computed:{
    	...mapState('start', ['hotels']),
    	...mapState('shop', ['currency']),
		hotelsList() {
			return sortalphabetically( this.hotels.filter((hotel) => hotel.dingusCode))
		},

		configRangeDate(){
			/* Si prop year  es igual a currentYear la fecha minima deber ser igual a hoy y el limite es hasta el 31 de enero del prox año
			Si prop year es mayor a currentYear la fecha minima deber ser al primero de enero y limite hasta el 31 de enero del prox año
			*/

			const currentDate = moment().startOf('day')
			const nextYear = moment(currentDate).add(1, "years").format("YYYY-MM-DD")

			let config = { mode: 'range', minDate: 'today', maxDate: nextYear}

			if(this.contract){
				if(this.contract.membershipcode !== 'CLASSIC'){
					const dateEndContract = this.contract.dateEnd //fecha fin de contrato
					const maxDateUntilEnero =  nextYear //fecha max hasta el sig año
					const contractExpiresBeforeDateUntilEnero = moment(maxDateUntilEnero).isSameOrAfter(dateEndContract, "day" )
					//si el contrato vence antes de diciembre, uso la fecha fin de contrato, sino uso maxDateUntilEnero
					config = { mode: 'range', minDate: 'today', maxDate: contractExpiresBeforeDateUntilEnero ? dateEndContract : maxDateUntilEnero }
					if( this.year > this.currentYear){
						config.minDate = currentDate
						const contractExpiresBeforeDateUntilEnero = moment(maxDateUntilEnero).isSameOrAfter(dateEndContract, "day" )
						config.maxDate = contractExpiresBeforeDateUntilEnero ? dateEndContract : maxDateUntilEnero
					}
				}
				if(this.contract.membershipcode === 'CLASSIC'){
					config = { mode: 'range', minDate: 'today', maxDate: nextYear }
				}
			}
			return config
		},
		benefitSelected(){
			return this.benefits.find( benefit => benefit.isSelected )
		}
	},
	methods:{
		...mapActions('fivesClubCatalogs', ['getOwnerBookingBlack','getContractOwner', 'fetchBenefitsByYearContract']),
        ...mapActions('mySales', ['getRooms',]),
		...mapMutations('fivesClubCatalogs', ['setBenefitSelected']),
        ...mapMutations('shop', ['setOwners','setContract']),
		async handlerSearch(){
            this.isValidatingPreliminarData = true
            this.noNightsAvailable = false
            let dataHousingOwnerContract = null
            let contract = null
            let owner = null
            this.validityRangeDates = null
            this.validityContract = null //lo limpio
            this.validityCategoryRoom = null
            this.$emit('set-booking-availibility', null)            
            this.$emit('set-rooms', [])
            this.$emit('set-show-rooms', false)
            this.$emit('set-benefits', [])              

            const datesAreValid = this.datesAreValid()
            //si las fechas no son validas, no pasa de aquí
            if( !datesAreValid ){
                const message = 'Falta que se indique valor para Check in - Check out'
                this.validityRangeDates = { isValidDates: false, message}
                this.isValidatingPreliminarData = false
                return showAlertMessage('Fecha: Check in - Check out', "InfoIcon", `😰😰 ${message}`, "danger", 3000, "bottom-right" )
            }

            //pedir datos de la vivienda para obtener el contrato, si hotel y numero de vivienda no son nulos
            if( this.searchBooking.hotel && this.searchBooking.numberHouse ){
                const rawDataHousingOwnerContract = await this.getContractOwner({
                    number: this.searchBooking.numberHouse,
                    IdResorts: this.searchBooking.hotel  
                })
                
                
                dataHousingOwnerContract = rawDataHousingOwnerContract.length > 0 ? rawDataHousingOwnerContract[0] : null
                
                if( dataHousingOwnerContract ){ //pongo datos en contrato variable
                    contract = { ...dataHousingOwnerContract }
                    this.$emit('set-owner', contract?.owners[0])
                    owner = contract?.owners[0]
                    contract.idOwner = contract?.owners[0]?.idOwner
                    delete contract.documents
                    this.$emit('set-contract', contract)                    
                    this.setContract({ owner: contract?.owners[0], contracts: [contract], }) //mando owner y un listado de contratos
                }

            }
            // validar el contracto
            const { isValidContract, message } = this.contractIsValid(contract)

            if( !isValidContract ){
                this.validityContract = { isValidContract, message }
                this.isValidatingPreliminarData = false
                contract = null             
                this.$emit('set-contract', contract)
                this.setContract("")
                // return showAlertMessage('Contrato no válido', "InfoIcon", `😰😰 ${message}`, "danger", 3000, "bottom-right" )
            }

            // si contrato es valido
            if( isValidContract ){

                if( !contract.categoryroomId || contract.categoryroomId === null ){
                    this.validityCategoryRoom = {
                        isValidCategoryRoom: false,
                        message: `La vivienda ${contract.housingnumber || ''} no tiene asignada una categoría de habitación.`
                    }
                    return this.isValidatingPreliminarData = false
                }

                //pido validacion de noches
                const dataSearch = this.payloadSearchRooms(this.searchBooking, contract, owner)
                this.$emit('set-booking-request', dataSearch) //necesarion en roomList              
                const rawRooms = await this.getRooms(dataSearch) // rawRooms

                if( rawRooms.length === 0 ){
                    this.noNightsAvailable = true
                    this.isValidatingPreliminarData = false 
					showAlertMessage("No tiene noches disponibles", "InfoIcon", "Parece que no tiene noches disponibles. Pruebe con otros filtros", "danger", 5000, "bottom-right")                                 
                    return
                }
				
                if(rawRooms.length > 0 ){
                    const rooms = this.formatRooms(rawRooms)
                    this.$emit("set-rooms", rooms)
                }
                //fin validacion de noches              

                //valido si hay blackout, cuttoff, necesita autorizacion
                const { isInvalidNights, needAuthorization, existAReservation, reasonAuthorization, hasReservationsNearDates } = await this.validateNights(contract)

                if( isInvalidNights ){
                    this.searchBooking.blackout = 0
                    this.isValidatingPreliminarData = false
                    return this.$swal.fire({
                        icon: 'error',
                        title: 'Noches insuficientes',
                        text: 'La reservación correspondiente no tiene noches suficientes'
                    })
                }
                //si el contracto es CLASSIC y la respuesta de getOwnerBookingBlack trae un additional en true
                if( existAReservation ){
                    this.searchBooking.blackout = 0
                    this.isValidatingPreliminarData = false
                    return this.$swal.fire({
                        icon: 'warning',
                        title: 'Una reserva encontrada',
                        text: 'Ya existe una reservacion dentro de estos dias.'
                    })
                }
                // Si hay reservaciones Cerca
                if( hasReservationsNearDates ){
                    this.isValidatingPreliminarData = false
                    this.searchBooking.blackout = 0
                    const manyBooks = hasReservationsNearDates?.data?.length > 1
                    await this.nearDatesModal({
                        icon: 'AlertCircleIcon',
                        title: manyBooks ? 'Se han encontrado varias reservas cercanas' : 'Una reserva cercana encontrada',
                        message: `${ manyBooks ? 'Existen reservaciones' : 'Hay una reserva'} cerca de los dias seleccionados. Elija otras fechas con una diferencia de ${hasReservationsNearDates?.lapse} días a ${ manyBooks ? 'estas':'esta'}`,
                        data: hasReservationsNearDates?.data
                    })
                    return
                }
                //si necesita autorización
                if( needAuthorization ){
                    const confirmAuthorization = this.searchBooking?.toEdit || await this.confirmAuthorization()
                    if(confirmAuthorization){

                        dataSearch.needAuthorization = true
                        dataSearch.reasonAuthorization = reasonAuthorization
                        this.$emit('set-booking-request', dataSearch) //necesarion en roomList
                    }
                    if (!confirmAuthorization){
                        dataSearch.needAuthorization = false
                        dataSearch.reasonAuthorization = ''
                        this.$emit('set-booking-request', dataSearch) //necesarion en roomList
                        this.isValidatingPreliminarData = false
                        return false    //termino el proceso aquí
                    }
                }

                //pido los beneficios aquí, si se saltó todas las validaciones  
                this.$emit('set-is-loading-benefits', true)
                const benefits = await this.getBenefits({idContract: contract.id, year: this.year}) 
                this.$emit('set-benefits', benefits)                
                this.$emit('set-is-loading-benefits', false)
                //fin pedidad de los beneficios aquí

                this.$emit('set-show-rooms', true)
            } 

            this.isValidatingPreliminarData = false
        },
		async validateNights(contract){
			const { dateIn, dateOut } = this.searchBooking
			const payload = {				
				dateIn,
				dateOut,
				idBooking: 0,			
				idContract: contract.id,
				idOwner: contract.idOwner, //id del owner, está en contrato
				isEdit: false,	
			}
			/*{
				"status": true, "data": "This reservation cannot be processed because it has a current cutoff.",
				"blackout": 0, "blackoutValid": false, "additional": false, "nights": 11, "cutoff": true,
				"info": { "name": "Cutoff | Baja post verano 2022", "total": 30 }, "message": "Valid data."
			}*/
			const {  status, cutoff, blackoutValid, additional, info, booksNear } = await this.getOwnerBookingBlack(payload) //desestructuro respuesta
			/*
				isInvalidNights sí noches es cero o blackoout -1 --> isInvalidNights false, pasa el proceso de la rsv
				needAuthorization si status o cuttoff es true --> needAuthorization en true no pasa la rsv, hasta aceptar la authorization
			*/
			// this.searchBooking.blackout =  blackout

			const data = {				
				status, cutoff,
				authorization: status, //de status
				blackoutValid,
				additional,				
				message: info ? `${info?.name || '' }, días: ${info?.total || '' } `: '',
			}
			
			this.$emit('set-booking-availibility', data)			

			return {	
				isInvalidNights: false,			
				needAuthorization: status || cutoff,
				existAReservation: contract.membershipcode === 'CLASSIC' && additional,
				reasonAuthorization : "Nombre: " + info?.name || '' + " dias: " + info?.total || '',
				hasReservationsNearDates: booksNear
			}
		},
		async confirmAuthorization() {
			const { isConfirmed } = await this.$swal.fire({
				title: "Necesita autorización",
				text: `No cumple los requisitos para realizar una reserva ¿Quiere solicitar una autorización?`,
				icon: "warning",
				showDenyButton: true,
				confirmButtonText: "Solicitar",
				denyButtonText: "Cancelar",
				reverseButtons: true,
				customClass: {
					actions: 'my-actions',
					confirmButton: 'mr-1 order-1',
					denyButton: 'order-2',
				}
			})
			return isConfirmed
		},
		contractIsValid(contract){

			if(!contract){
				return { isValidContract: false, message: 'No se encontró un contrato'}
			}

			if( contract && contract.statuscontractname !== 'FIRMADO' ){
				return { isValidContract: false, message: 'El contrato es diferente a FIRMADO'}
			}

			if( contract && contract.statuscontractname === 'FIRMADO' ){

				//validando que las fechas del contrato esté vigente
				const { isValidContract, message } = this.contractExpired(contract)
				
				if( !isValidContract ){
					return { isValidContract, message}					
				}

				//verifico que el rango de fechas de checkin/checkout esten en el rango de fechas del contrato
				const { isValidContract: isValid, message: msg } = this.datesInContractRange(contract)

				if( !isValid ){
					return { isValidContract: isValid, message: msg}					
				}
				
				//si todo sale bien, llega aquí como contrato válido
				return { isValidContract: true, message: ''}												
			}

		},
		contractExpired(contract){
			const fechaActual = new Date()
			// Definir las fechas de inicio y fin del rango
			const fechaInicio = new Date(contract.dateStart)
			const fechaFin = new Date(contract.dateEnd)

			const isValidContract = fechaActual >= fechaInicio && fechaActual <= fechaFin // isValidContract
			const msgExpiredContract = `Se encontró el contrato <b>${contract.code || '' }</b> pero no está vigente. <br>
										<b>Vigencia de contrato:</b> ${contract.dateStart} ➡️ ${contract.dateEnd}
										`
			const message = !isValidContract ? msgExpiredContract : 'Contrato vigente'

			return { isValidContract, message }
		},
		datesInContractRange(contract){
			let isValidCheckIn = false
			let isValidCheckOut = false

			const dateCheckin = new Date( this.searchBooking.dateIn )
			const dateCheckOut = new Date( this.searchBooking.dateOut )

			const start = new Date( contract.dateStart ) //fecha inicio de contato
			const end = new Date( contract.dateEnd ) //fecha fin de contato

			isValidCheckIn = dateCheckin >= start && dateCheckin <= end
			isValidCheckOut = dateCheckOut >= start && dateCheckOut <= end

			const isValidContract = isValidCheckIn && isValidCheckOut
			const message = !isValidContract ?
							`Las fechas de Check In y Check Out  debe estar entre las fechas del contrato. <br>
							 <strong>Check in / check out:</strong> ${this.searchBooking.dateIn} ➡️ ${this.searchBooking.dateOut}. <br>					
							 <strong>Vigencia del contrato:</strong> ${contract.dateStart} ➡️ ${contract.dateEnd}
							 .`
							: ''

			//si fechas de checkin y checkout estan en el rango de fechas del contrato
			return { isValidContract, message}
		},
		payloadSearchRooms(dataRaw, contract, owner){

			const data = toJson({...dataRaw}) // rompo la referencia con desestructuración            
            const hotelData = this.hotels.find((hotel) => hotel.id === data.hotel )

            data.vendorcode  = 'TFCOWNERS' // vendor code propio
            data.owner = owner  // tomo el objeto owner con el que estamos trabajando
            data.promotionCode = "DROP 100"
            data.children = data.numberChildren            
            data.adults = data.adultsNumber
            data.resortId = data.hotel
            data.idHotel = data.resortId
            data.hotel = hotelData.dingusCode || null
            data.occupanttype = data.occupantType
            data.idioma = "en"
            data.isManualRate = false
            data.manualRate = ""
            data.isguest = false
            data.origin = 2
            data.rate = ""
            data.rateplanid = ""
            data.roomCode = ""
            data.tarifa = ""
            data.roomType = contract.categoryroomName // '3BST' //  lo tomo del contracto o tambien desde el owner en this.owner.housing[0].categoryroomName
            data.idContract = contract.id
            data.membership = contract.membershipid
            // data.categoryroomId = contract.categoryroomId
			data.isWeb = false
            delete data.numberChildren
            delete data.adultsNumber         
            delete data.rangeDate
			
			return data
		},
        formatRooms(rooms){
            rooms.forEach(room => {
                room.uuid = stringAleatorio()
                // room.rates = room.rates.filter(rate => rate.MealPlanCodes === 'EPSOC')
                room.detailSelected = room.rates.length ? room.rates[0] : [] // detail selected obtiene el
                room.priceInit = room.rates.length ? room.rates[0].AmountAfterTax : '0.00'
                // room.RoomImg = null //temporal
                // room.multimedias = [] //temporal
            })
            return rooms
        },		
		setChilds(numberChilds) {
			let childs = []
			for (let i = 0; i < numberChilds; i++) {
				const key = stringAleatorio()
				childs.push({ edad: 1, email: '', adult: false, ismain: false, key,  name: 'NA', lastname: 'NA', phone: '', })
			}
			this.searchBooking.ninos = childs
		},
		setCheckInChekOut(rangeDate) {
			if (rangeDate.includes("to")) {
				const splitDate = rangeDate.split(" to ")
				this.searchBooking.dateIn = splitDate[0]
				this.searchBooking.dateOut = splitDate[1]
				const inicio = moment(splitDate[0])
				const fin = moment(splitDate[1])
				this.searchBooking.nights = splitDate[0] && splitDate[1] ? fin.diff(inicio, "days") : 0
			} else {
				this.searchBooking.dateIn = ""
				this.searchBooking.dateOut = ""
				this.searchBooking.nights = 0
			}
		},
		deleteChild(keyChild) {
			this.searchBooking.ninos = this.searchBooking.ninos.filter((age) => age.key !== keyChild )
			this.searchBooking.numberChildren = this.searchBooking.numberChildren - 1
		},
		datesAreValid(){
			const { nights, dateIn, dateOut } = this.searchBooking
			return nights > 0 && dateIn != '' && dateOut != ''
		},
		
		
        async getBenefits( data ){
			const rawBenefits = await this.fetchBenefitsByYearContract({idContract: data.idContract, year: data.year, typeBenefit:'Noches'})

            return rawBenefits.length ? this.formatBenefits(rawBenefits) : []
		},
		formatBenefits( rawBenefits ){
            let benefits = []
            rawBenefits?.forEach( beneficio => {
                const { benefit } = beneficio
                //solo necesito los que vienen en false
                if( !benefit.includebooking ){
                    benefit.showCheckSelect = benefit.code !== "TA"
                    benefit.isSelected = false // por defecto no está marcado
                    benefits.push( benefit )
                }
            })        
            return benefits
        },
		resetSearchForm(){
			this.searchBooking.hotel = null
			this.searchBooking.rangeDate = null
			this.searchBooking.nights = 0
			this.searchBooking.numberHouse = ''

			this.searchBooking.adultsNumber = 1
			this.searchBooking.numberChildren = 0
			this.searchBooking.occupantType = 1
			this.searchBooking.internalNotes = ''
			this.searchBooking.ninos = []
			this.searchBooking.dateIn = ''
			this.searchBooking.dateOut = ''
            this.$refs.searchAvailibilityForm.reset(); //reset form

			this.$emit('set-year', this.currentYear ) // reseteo el año, por si acaso
			this.$emit('set-benefits', [])   // emito al padre la limpieza de los benefits
			this.$emit('set-rooms', [])   // emito al padre la limpieza de los rooms
			this.validityRangeDates = null
			this.validityContract = null //lo limpio
			this.validityCategoryRoom = null
			
			this.noNightsAvailable = false

		},
		async nearDatesModal(info){
			const {icon, title, message, data} = info
			const h = this.$createElement

			// const titleVNode = h('label', { domProps: { innerHTML: title } })

			const datafields = [
				{key: 'code', label: 'Folio reserva'},
				{key: 'datein', label: 'Check in'},
				{key: 'dateout', label: 'Check out'}
			]

			const messageVNode = h('div', { class: ['foobar'] }, [
				// h('feather-icon', { props: {icon} }),
				h('p', { class: ['text-center mb-1'] }, [
					`${message}`,
					h('br'),
					h('strong', [`Sus fechas: ${this.searchBooking.dateIn} ➡️ ${this.searchBooking.dateOut}`]),
				]),
				h('b-table', {
					props: {
						items: data,
						fields: datafields,
						small: true,
						responsive: true,
					}
				})
			])

			const modal = await this.$bvModal.msgBoxOk([messageVNode],{
				title,
				size: 'sm',
				centered: true,
				hideHeaderClose: false,
			})

			return modal
		},
	},
	watch:{
		// Si el año obtenido desde benefits cambia, reseteo si se tiene algo en las fechas del calendario
		year: {
			deep: true,
			handler() {
				this.searchBooking.dateIn = ""
				this.searchBooking.dateOut = ""
				this.searchBooking.nights = 0
				this.searchBooking.rangeDate = null
			},
		},
		currency: {
			deep: true,
			async handler() {
				await this.handlerSearch()
			},
		}
	}
}
</script>
<style lang="scss" scoped>
	@import "@core/scss/vue/libs/vue-flatpicker.scss";
	.b-nights{
		font-size: 1.2rem;
	}
	.b-icon {
		height: 24px;
		width: 22px;
		font-size: 24px;
	}
	.my-actions { margin: 0 auto; margin-top: 1rem; }
	.order-1 { order: 1; }
	.loadRooms{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		row-gap: 1rem;
	}
	
	.alert-p{
		margin: 0;
		font-size: 1rem;
		font-weight: 500;
	}
	.alertas{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin: 0;
	}
	.alert-msg{		
		max-width: 720px;
		width: 400px;
		width: 40%; 
		text-align: center;
		color: #212121;
		// padding: .5rem 1rem;
		font-size: .95rem;		
	}


</style>
